import type { DialogProps } from '@radix-ui/react-dialog';
import { Link } from '@remix-run/react';
import { RotateCcw } from 'lucide-react';
import { useTracking } from 'react-tracking';
import { buttonVariants } from '~/components/button';
import {
  ResponsiveDialog,
  ResponsiveDialogBody,
  ResponsiveDialogClose,
  ResponsiveDialogContent,
  ResponsiveDialogFooter,
} from '~/components/responsive-dialog';
import { VStack } from '~/components/stack';
import { ROUTES } from '~/utils/routes';

export function LearningRetakeDialog(props: DialogProps) {
  const { trackEvent } = useTracking();

  return (
    <ResponsiveDialog dismissible={false} {...props}>
      <ResponsiveDialogContent
        withCloseButton={false}
        className="lg:max-w-[400px]"
      >
        <ResponsiveDialogBody>
          <VStack className="gap-4 justify-center items-center py-8">
            <div className="bg-theme text-white p-5 rounded-xl">
              <RotateCcw />
            </div>
            <h2 className="text-2xl font-bold text-center">Don't Miss Out!</h2>
            <p className="text-center leading-relaxed">
              We noticed you decided not to upgrade. How about revisiting and
              rerating those tricky questions to strengthen your grasp on the
              material?
            </p>
          </VStack>
        </ResponsiveDialogBody>
        <ResponsiveDialogFooter>
          <VStack className="w-full gap-3">
            <ResponsiveDialogClose asChild>
              <Link
                to={ROUTES.RETAKE}
                className={buttonVariants({ className: 'flex-1' })}
                onClick={() => {
                  trackEvent({ event: 'retake_popup_accept' });
                }}
              >
                Retake incorrect
              </Link>
            </ResponsiveDialogClose>
            <ResponsiveDialogClose asChild>
              <Link
                to={ROUTES.DECKS.BROWSE}
                className={buttonVariants({
                  variant: 'ghost',
                  className: 'flex-1',
                })}
                onClick={() => {
                  trackEvent({ event: 'retake_popup_cancel' });
                }}
              >
                Cancel
              </Link>
            </ResponsiveDialogClose>
          </VStack>
        </ResponsiveDialogFooter>
      </ResponsiveDialogContent>
    </ResponsiveDialog>
  );
}
