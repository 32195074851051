import type { APIMethodParams } from '@medizzy/api';
import { z } from 'zod';

export const createCheckoutSessionResponseSchema = z.object({
  url: z.string(),
});

export function createCheckoutSession({ client }: APIMethodParams) {
  return client
    .url('/v2/payments/stripe/checkout/sessions')
    .post()
    .valid(createCheckoutSessionResponseSchema);
}
